import React from "react"
import Countries from "./Country"
import * as styles from "./CountrySelect.module.scss"

interface CountrySelectProps {
  register: any
}

export const CountrySelect = ({ register }: CountrySelectProps) => {
  const countries = Countries.map(country => {
    return {
      value: country.dial_code,
      label: `${country.code} | ${country.dial_code}`,
    }
  })

  countries.sort((a, b) => a.label.localeCompare(b.label))
  const USIndex = countries.findIndex(country => country.label === "US | +1")

  return (
    <div className={styles.countrySelectContainer}>
      <select {...register}>
        {countries.map(country => {
          return (
            <option
              value={country.label}
              selected={countries[USIndex] === country}
            >
              {country.label}
            </option>
          )
        })}
      </select>
    </div>
  )
}
