import yup from "../../lib/yup"

export const contactNumber = yup
  .string()
  .when(["country"], (country, schema) => {
    const _country = country.split("|")[0].trim()
    return schema.phone(
      _country,
      true,
      `Please enter a valid ${_country} phone number`
    )
  })
  .required("Please enter your contact number")
