import React, { useEffect, useMemo, useState } from "react"

import { navigate } from "gatsby"
import { SubmitHandler, useForm } from "react-hook-form"
import FormLayout from "../../../components/FormLayout/FormLayout"
import InputUpload from "../../../components/InputUpload/InputUpload"

import { ArtistSignUpInputs } from "../../../../types/pages/artist-sign-up"

import * as styles from "./ArtistSignUp.module.scss"
import { useWallet } from "use-wallet"
import { ConnectWalletMessage } from "../../../components/ConnectWalletMessage/ConnectWalletMessage"
import { CustomTextInput } from "../../../components/CustomTextInput/CustomTextInput"
import { CustomTextAreaInput } from "../../../components/CustomTextAreaInput/CustomTextAreaInput"
import Seo from "../../../components/Seo/seo"
import { yupResolver } from "@hookform/resolvers/yup"
import { schema } from "../../../validation/signups/artist"
import { fromJSON } from "../../../lib/form-data"
import { SignUpButton } from "../../../components/SignUpButton/SignUpButton"
import { UserNameInput } from "../../../components/UserNameInput/UserNameInput"
import { ProgressBar } from "../../../components/ProgressBar/ProgressBar"
import {
  ErrorType,
  ServerErrorMessage,
  SignUpType,
} from "../../../components/ServerErrorMessage/ServerErrorMessage"
import { ContactNumberInput } from "../../../components/ContactNumberInput/ContactNumberInput"
import { PasswordRequirements } from "../../../components/PasswordRequirements/PasswordRequirements"
import { remove } from "lodash"
import InputUploadImage from "../../../components/InputUploadImage/InputUploadImage"

const ArtistSignUp = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<ArtistSignUpInputs>({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: { country: "US | +1" },
  })
  const [, setWalletError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const wallet = useWallet()
  const [showServerError, setShowErrorMessage] = useState(false)
  const [serverErrorType, setServerErrorType] = useState<ErrorType>(
    ErrorType.Exist
  )

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 10)
  }, [])

  useMemo(() => {
    if (wallet.account) {
      setValue("walletAddress", wallet.account)
    }
  }, [wallet.account])

  const select = watch("country")
  const onSubmit: SubmitHandler<ArtistSignUpInputs> = async data => {
    if (wallet.status !== "connected") {
      setWalletError(true)
      return
    }
    delete data.country
    try {
      setIsLoading(true)
      setShowErrorMessage(false)
      data.facebookUrl = data.facebookUrl ?? ""
      data.twitterUrl = data.twitterUrl ?? ""
      data.instagramUrl = data.instagramUrl ?? ""
      data.profilePicture = data.profilePicture.shift()
      data.coverImage = data.coverImage.shift()
      console.log(data)
      const formData = fromJSON(data)

      const response = await fetch(
        new URL("/artist", process.env.GATSBY_API_URL).toString(),
        {
          method: "POST",
          body: formData,
        }
      )
      console.log(response)
      if (response.ok) {
        navigate("/sign-up/confirmation")
      } else {
        const res = await response.json()
        if (res.statusCode >= 500) {
          setServerErrorType(ErrorType.Unknown)
        } else {
          setServerErrorType(ErrorType.Exist)
        }
        alert(await response.text())
        setShowErrorMessage(true)
      }
    } catch (ex) {
      console.error("Error", ex)
      setShowErrorMessage(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Seo title="Artist Sign up" />
      {isLoading && <ProgressBar />}
      <FormLayout
        title="Artist Sign Up"
        className={styles.artistFormBackground}
        icon="artistSquare"
        backgroundClassName={styles.artistVectorBackground}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.artistSignUpForm}>
          <CustomTextInput
            errorMessage={errors.firstName?.message}
            placeholder="First Name"
            register={register("firstName")}
          />
          <CustomTextInput
            errorMessage={errors.middleName?.message}
            placeholder="Middle Name"
            isOptional={true}
            register={register("middleName")}
          />
          <CustomTextInput
            errorMessage={errors.lastName?.message}
            placeholder="Last Name"
            register={register("lastName")}
          />
          <CustomTextInput
            errorMessage={errors.email?.message}
            placeholder="Email"
            register={register("email")}
          />
          <ContactNumberInput
            errorMessage={errors.contactNumber?.message}
            placeholder={"Contact Number"}
            register={register}
          />

          <CustomTextInput
            errorMessage={errors.location?.message}
            placeholder={"Location"}
            register={register("location")}
          />
          <CustomTextInput
            errorMessage={errors.experience?.message}
            placeholder={"Have you work with NFTs before?"}
            register={register("experience")}
          />

          <div className={styles.artistWalletContainer}>
            <ConnectWalletMessage />
          </div>
        </div>

        <div className={styles.artistSignUpForm}>
          <UserNameInput
            errorMessage={errors.username?.message}
            placeholder="Username"
            register={register("username")}
          />
          {/* <div className={styles.artistPasswordContainer}>
            <CustomTextInput
              errorMessage={errors.password?.message}
              placeholder="Password"
              type="password"
              register={register("password")}
            />
            <PasswordRequirements
              classname={styles.artistPasswordRequirements}
            />
            <CustomTextInput
              errorMessage={errors.confirmPassword?.message}
              placeholder="Re-enter Password"
              type="password"
              register={register("confirmPassword")}
            />
          </div> */}
          <CustomTextInput
            errorMessage={errors.website?.message}
            placeholder={"Website"}
            register={register("website")}
          />
          <CustomTextInput
            errorMessage={errors.facebookUrl?.message}
            placeholder={"Facebook URL"}
            isOptional={true}
            register={register("facebookUrl")}
          />
          <CustomTextInput
            errorMessage={errors.instagramUrl?.message}
            placeholder={"Instagram URL"}
            isOptional={true}
            register={register("instagramUrl")}
          />
          <CustomTextInput
            errorMessage={errors.twitterUrl?.message}
            placeholder={"Twitter URL"}
            isOptional={true}
            register={register("twitterUrl")}
          />
          <InputUpload
            register={register("references")}
            setValue={setValue}
            errors={errors}
          />
          <InputUploadImage
            register={register("profilePicture")}
            setValue={setValue}
            errors={errors}
            name={"profilePicture"}
            errorMessage={errors.profilePicture?.message}
            maxFiles={1}
            subtitle={"Profile Picture (1 example, 15mb limit)"}
            title={"Drag and drop your profile picture here."}
          />
          <InputUploadImage
            register={register("coverImage")}
            setValue={setValue}
            errors={errors}
            name={"coverImage"}
            errorMessage={errors.coverImage?.message}
            maxFiles={1}
            subtitle={"Cover Image (1 example, 15mb limit)"}
            title={"Drag and drop your Cover Image here."}
          />
          <CustomTextAreaInput
            errorMessage={errors.artDescription?.message}
            placeholder={"Artist Description (1000 characters)"}
            register={register("artDescription")}
          />
        </div>
        <div className={styles.artistSignUpButtons}>
          <SignUpButton
            title={"→ Send Application"}
            spanText={"All fields are required unless otherwise indicated"}
            isDisabled={
              wallet.status !== "connected" ||
              Object.keys(errors).length > 0 ||
              isLoading
            }
          />
          {showServerError && (
            <ServerErrorMessage
              errorType={serverErrorType}
              signuptype={SignUpType.Artist}
              className={styles.artistSignUpServerError}
            />
          )}
        </div>
      </FormLayout>
    </>
  )
}

export default ArtistSignUp
