import React from "react"
import { CountrySelect } from "../CountrySelect/CountrySelect"
import {
  CustomTextInput,
  CustomTextInputProps,
} from "../CustomTextInput/CustomTextInput"
import * as styles from "./ContactNumberInput.module.scss"

export const ContactNumberInput = ({
  errorMessage,
  placeholder,
  register,
}: CustomTextInputProps) => {
  return (
    <div className={styles.contactNumberInput}>
      <CountrySelect register={register("country")} />
      <CustomTextInput
        errorMessage={errorMessage}
        placeholder={placeholder}
        register={register("contactNumber")}
      />
    </div>
  )
}
